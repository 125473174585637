// библиотеки
import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('HeaderStore', () => {
  // Состояния шапки и элементов, которые на неё влияют
  const state = reactive({
    isSliderTopDark: false,
    isCatalogOpened: false,
  });

  const isInverseMode = computed(() => {
    // Если открыт попап каталог
    if (state.isCatalogOpened) {
      return false;
    }

    // Если слайдер под шапкой темный
    if (state.isSliderTopDark) {
      return true;
    }

    return false;
  });

  return { state, isInverseMode };
});
